import LocalizedStrings from "react-localization";

export default new LocalizedStrings({
  de: {
    startGame: "Spiel starten",
    joinGame: "Spiel beitreten",
    createGame: "Spiel erstellen",
    username: "Benutzernamen eingeben",
    userlist: "Benutzerliste",
    settings: "Einstellungen",
    rounds: "Runden",
    time: "Zeit",
    invite: "Einladen",
    send: "Senden",
    enterMessage: "Nachricht eingeben...",
    copiedToClipboard: "Erfolgreich kopiert.",
    selectAll: "Alles auswählen",
    deselectAll: "Alles abwählen",
    selectGenre: "Bitte Genre auswählen...",
    players: "Spieler",
  },
  en: {
    startGame: "Start game",
    joinGame: "Join game",
    createGame: "Create game",
    username: "Enter a username",
    userlist: "Userlist",
    settings: "Settings",
    rounds: "Rounds",
    time: "Time",
    invite: "Invite",
    send: "Send",
    enterMessage: "Enter message...",
    copiedToClipboard: "Successfully copied",
    selectAll: "Select all",
    deselectAll: "Deselect all",
    selectGenre: "Please select genre...",
    players: "Players",
  },
});
