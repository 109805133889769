import React, { useRef, useCallback, useContext, useEffect } from 'react';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { AppContext } from '../contexts/AppContext';
import '../css/global.css';
import '../css/emojipicker.css';
import { Picker } from 'emoji-picker-element';
interface Emoji {
  unicode: string;
}

interface EmojiPickerProps {
  setSelectedEmojis: (data: string[], emitData: boolean) => void;
  selectedEmojisRef: React.MutableRefObject<string[]>;
  width: string;
  height: string;
}

function EmojiPicker({ setSelectedEmojis, selectedEmojisRef, width, height }: EmojiPickerProps) {
  const { state } = useContext(AppContext);

  const renderEmojis = useCallback(() => {
    const previousEmojiPicker = document.querySelector('emoji-picker');

    if (previousEmojiPicker) previousEmojiPicker.remove();

    const picker: any = new Picker({ locale: 'de', dataSource: '/emojipicker/de/emojis.json' });
    document.querySelector('#emojiContainer')?.insertAdjacentElement('afterbegin', picker);

    const style = document.createElement('style');
    style.textContent = `:host .favorites {display: none}`;
    picker.shadowRoot.appendChild(style);

    picker.addEventListener('emoji-click', (event: { detail: Emoji }) => {
      setSelectedEmojis([...selectedEmojisRef.current, event.detail.unicode], true);
    });
  }, []);

  useEffect(() => {
    renderEmojis();
  }, [state.is_max_992, renderEmojis]);

  return <div id='emojiContainer' style={{ width, height }}></div>;
}

export default EmojiPicker;
