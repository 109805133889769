import { Card, Col, Popover, Row } from 'antd';
import React, { useContext, useEffect, useState, useCallback } from 'react';
import '../css/global.css';
import { GameEvents } from '../utils/constants';
import { AppContext } from '../contexts/AppContext';
import { SmileOutlined } from '@ant-design/icons';
import EmojiPicker from './EmojiPicker';

interface Emoji {
  unicode: string;
}

const EmojiCriber: React.FC = () => {
  const { state, setState } = useContext(AppContext);
  const [selectedEmojis, _setSelectedEmojis] = useState<string[]>([]);
  const selectedEmojisRef = React.useRef(selectedEmojis);

  useEffect(() => {
    if (state.socket && state.user) {
      state.socket.on(GameEvents.EMOJIS, (data: any) => {
        setSelectedEmojis(data.emojis, false);
      });
    }
  }, [state.socket]);

  const setSelectedEmojis = (data: string[], emitData: boolean) => {
    selectedEmojisRef.current = data;

    if (state && state.socket && emitData) {
      state.socket.emit(GameEvents.EMOJIS, data);
    }
    _setSelectedEmojis(data);
  };

  const removeEmoji = (index: number) => {
    selectedEmojisRef.current.splice(index, 1);
    setSelectedEmojis([...selectedEmojisRef.current], true);
  };

  const renderSelectedEmojis = () => {
    return selectedEmojis.map((emoji, index) => {
      return (
        <div
          className='emojicribe'
          onClick={() => {
            if (state.user?.active) removeEmoji(index);
          }}
        >
          {emoji}
        </div>
      );
    });
  };

  const content = <EmojiPicker setSelectedEmojis={setSelectedEmojis} selectedEmojisRef={selectedEmojisRef} width='80vw' height='55vh'></EmojiPicker>;

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }} className='full-height full-width'>
      <Row style={{ flexGrow: 1, marginBottom: '8px', height: '20%' }} justify='center' align='middle'>
        <Col className='full-height' span={24}>
          <Card className='full-height' style={{ justifyContent: 'center', alignItems: 'center', display: 'flex', flexWrap: 'wrap' }}>
            <div style={{ justifyContent: 'center', alignItems: 'center', display: 'flex', flexWrap: 'wrap' }} className='full-height emojicribe-container'>
              {renderSelectedEmojis()}
              {state.is_max_992 && state.user && state.user.active ? (
                <Popover placement='bottomLeft' content={content} trigger='click'>
                  <SmileOutlined className='emijicribe--icon' />
                </Popover>
              ) : (
                <></>
              )}
            </div>
          </Card>
        </Col>
      </Row>
      {!state.is_max_992 && state.user && state.user.active ? (
        <Row className='full-height'>
          <Col className='full-height' span={24}>
            <Card style={{ display: 'flex', position: 'relative' }} className='full-height'>
              <EmojiPicker setSelectedEmojis={setSelectedEmojis} selectedEmojisRef={selectedEmojisRef} width='100%' height='100%'></EmojiPicker>
            </Card>
          </Col>
        </Row>
      ) : (
        <></>
      )}
    </div>
  );
};

export default EmojiCriber;
