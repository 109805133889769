import React, { useState, useEffect } from 'react';
import { Layout, Menu, Dropdown, Button } from 'antd';
import { BrowserRouter as Router, Route, Link, Routes } from 'react-router-dom';
import { AppContext, appContextDefaultValue } from './contexts/AppContext';
import { AppContextType } from './types/Interfaces';
import STRINGS from './utils/languages';

import './css/global.css';
import './css/navigation.css';

import Main from './content/Main';
import Lobby from './content/Lobby';
import Game from './content/Game';
import { IS_MAX_576, IS_MAX_992 } from './utils/constants';

const { Header, Content } = Layout;

const App: React.FC = () => {
  const [state, setState] = useState<AppContextType>(appContextDefaultValue.state);

  const switchLanguage = (language: string) => {
    setState((state) => ({ ...state, language: language }));
    STRINGS.setLanguage(language);
  };
  const menu = (
    <Menu>
      {STRINGS.getAvailableLanguages().map((language) => {
        return (
          <Menu.Item onClick={() => switchLanguage(language)} key={language}>
            {language}
          </Menu.Item>
        );
      })}
    </Menu>
  );

  function handleWindowSizeChange() {
    setState((state) => ({
      ...state,
      is_max_992: window.innerWidth <= IS_MAX_992,
      is_max_576: window.innerWidth <= IS_MAX_576,
    }));
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  return (
    <AppContext.Provider value={{ state, setState }}>
      <Layout className='full-height-100vh'>
        <Header className='header'>
          <div style={{ marginLeft: '8px' }} className='logo'>
            <Link to='/'>HOME</Link>
          </div>
          <Menu title='test' theme='dark' mode='horizontal'></Menu>
          <Dropdown overlay={menu} trigger={['click']} placement='bottomRight'>
            <Button>{state.language ? state.language : STRINGS.getLanguage()}</Button>
          </Dropdown>
        </Header>
        <Layout className='full-height'>
          <Layout className='full-height' style={{ background: 'white' }}>
            <Content className='full-height' style={{ margin: '8px' }}>
              <Routes>
                <Route path='/' element={<Main />} />
                <Route path='/:roomId' element={<Main />} />
                <Route path='/lobby/:roomId' element={<Lobby />} />
                <Route path='/game/:roomId' element={<Game />} />
              </Routes>
            </Content>
          </Layout>
        </Layout>
      </Layout>
    </AppContext.Provider>
  );
};

export default App;
