import { ClockCircleOutlined, RetweetOutlined, LinkOutlined, PlayCircleOutlined } from '@ant-design/icons';
import { Col, Row, Card, Form, Select, Button, Space, Drawer, message } from 'antd';
import { AppContext } from '../contexts/AppContext';
import React, { useState, useContext, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Userlist from '../components/Userlist';
import '../css/global.css';
import STRINGS from '../utils/languages';
import { RoomSettings } from '../types/Interfaces';
import { RoomId } from '../types/Interfaces';
import copy from 'copy-to-clipboard';
import { GameEvents } from '../utils/constants';

const { Option } = Select;

const Lobby: React.FC = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const { state, setState } = useContext(AppContext);
  const [visible, setVisible] = useState(false);
  const [genres, setGenres] = useState<string[]>([]);
  const { roomId } = useParams<RoomId>();
  const showDrawer = () => {
    setVisible(true);
  };
  const onClose = () => {
    setVisible(false);
  };

  const emitSettings = () => {
    state.socket?.emit(GameEvents.SETTINGS, {
      rounds: form.getFieldValue('round'),
      time: form.getFieldValue('time'),
      genres: form.getFieldValue('genre'),
    });
  };

  useEffect(() => {
    if (state.socket && state.user && form) {
      state.socket.emit(GameEvents.USERS, () => {});

      state.socket.emit(GameEvents.GENRES, () => {});

      state.socket.on(GameEvents.USERS, (users: any) => {
        setState({
          ...state,
          users,
        });
      });

      state.socket.on(GameEvents.GENRES, (genres: string[]) => {
        setGenres(genres);
      });

      if (state.user && !state.user.createdRoom) {
        state.socket.emit(GameEvents.SETTINGS, () => {});
        state.socket.on(GameEvents.SETTINGS, (settings: RoomSettings) => {
          form.setFieldsValue({ round: settings.rounds });
          form.setFieldsValue({ time: settings.time });
          form.setFieldsValue({ genre: settings.genres });
        });

        state.socket.on(GameEvents.START, () => {
          navigate('/game/' + roomId);
        });
      }
    }
  }, [state.socket, state.user, form]);

  const deselectAll = () => {
    form.setFieldsValue({ genre: [] });
    emitSettings();
  };

  const selectAll = () => {
    form.setFieldsValue({ genre: genres });
    emitSettings();
  };

  const onFinish = (values: any) => {
    if (state.socket) {
      state.socket.emit(GameEvents.START, () => {});
      navigate('/game/' + roomId);
    }
  };

  return (
    <Card className='full-height' style={{ padding: '24px' }}>
      <Row gutter={[8, 8]} style={{ height: '80%' }}>
        {state.is_max_992 ? (
          <></>
        ) : (
          <Col span={24} md={12}>
            <Card size='default' title={STRINGS.userlist} className='full-height'>
              <Userlist users={state.users ? state.users : []}></Userlist>
            </Card>
          </Col>
        )}

        <Col span={24} md={state.is_max_992 ? 24 : 12}>
          <Card
            style={{ position: 'relative' }}
            size='default'
            title={
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <div>{STRINGS.settings}</div>
                {state.is_max_992 ? (
                  <div>
                    <Button onClick={showDrawer} size='small'>
                      {state.users ? state.users.length : 0} {STRINGS.players}
                    </Button>
                    <Drawer title={STRINGS.userlist} placement='right' closable={false} onClose={onClose} visible={visible}>
                      <Userlist users={state.users ? state.users : []}></Userlist>
                    </Drawer>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            }
            className='full-height'
          >
            <Form form={form} size='large' name='control-ref' onFinish={onFinish}>
              <Form.Item name='time' rules={[{ required: true }]}>
                <Select
                  disabled={state.user && state.user.createdRoom ? false : true}
                  onChange={emitSettings}
                  suffixIcon={<ClockCircleOutlined />}
                  placeholder={STRINGS.time}
                >
                  <Option value={30}>30s</Option>
                  <Option value={60}>60s</Option>
                  <Option value={90}>90s</Option>
                  <Option value={120}>120s</Option>
                  <Option value={150}>150s</Option>
                </Select>
              </Form.Item>
              <Form.Item name='round' rules={[{ required: true }]}>
                <Select
                  disabled={state.user && state.user.createdRoom ? false : true}
                  onChange={emitSettings}
                  suffixIcon={<RetweetOutlined />}
                  placeholder={STRINGS.rounds}
                >
                  <Option value={3}>3</Option>
                  <Option value={4}>4</Option>
                  <Option value={5}>5</Option>
                  <Option value={6}>6</Option>
                  <Option value={7}>7</Option>
                  <Option value={8}>8</Option>
                  <Option value={9}>9</Option>
                  <Option value={10}>10</Option>
                </Select>
              </Form.Item>
              <Form.Item name='genre' rules={[{ required: true }]}>
                <Select
                  disabled={state.user && state.user.createdRoom ? false : true}
                  onChange={emitSettings}
                  dropdownRender={(menu) => {
                    return (
                      <>
                        <Space style={{ margin: '8px' }}>
                          <Button onClick={() => selectAll()} size='small' type='primary'>
                            {STRINGS.selectAll}
                          </Button>
                          <Button onClick={() => deselectAll()} size='small'>
                            {STRINGS.deselectAll}
                          </Button>
                        </Space>

                        {menu}
                      </>
                    );
                  }}
                  mode='multiple'
                  allowClear
                  placeholder={STRINGS.selectGenre}
                >
                  {genres &&
                    genres.map((genre, index) => {
                      return (
                        <Option key={genre} value={genre}>
                          {genre}
                        </Option>
                      );
                    })}
                </Select>
              </Form.Item>

              <Form.Item>
                <Button
                  className='buttonMargin'
                  icon={<LinkOutlined />}
                  type='primary'
                  onClick={() => {
                    if (copy(window.location.host + '/' + roomId)) message.success(STRINGS.copiedToClipboard);
                  }}
                >
                  {STRINGS.invite}
                </Button>
                {state.user && state.user.createdRoom ? (
                  <Button icon={<PlayCircleOutlined />} htmlType='submit'>
                    {STRINGS.startGame}
                  </Button>
                ) : (
                  <></>
                )}
              </Form.Item>
            </Form>
          </Card>
        </Col>
      </Row>
    </Card>
  );
};

export default Lobby;
