import { Card, Col, Form, List, Row, Input, Button, Drawer } from 'antd';
import React, { useRef, useState, useEffect, useContext } from 'react';
import '../css/global.css';
import Userlist from './Userlist';
import '../css/chat.css';
import STRINGS from '../utils/languages';
import { AppContext } from '../contexts/AppContext';
import { AppContextType, MessageInterface } from '../types/Interfaces';
import { GameEvents } from '../utils/constants';
import { ConsoleSqlOutlined } from '@ant-design/icons';

const { Search } = Input;

const Chat: React.FC = () => {
  const [form] = Form.useForm();
  const [chatMessages, setChatMessages] = useState<MessageInterface[]>([]);
  const { state, setState } = useContext(AppContext);
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (state.socket) {
      state.socket.on(GameEvents.MESSAGE, (message: MessageInterface) => {
        chatMessages.push(message);
        setChatMessages([...chatMessages]);
      });
    }
  }, [state.socket]);

  useEffect(() => {
    const objDiv = document.getElementById('chatbody')!;

    if (objDiv) objDiv.scrollTop = objDiv.scrollHeight;
  }, [chatMessages]);

  const showDrawer = () => {
    setVisible(true);
  };
  const onClose = () => {
    setVisible(false);
  };

  const getReadableTime = (timestamp: Date) => {
    const date = new Date(timestamp);
    return date.getHours().toString().padStart(2, '0') + ':' + date.getMinutes().toString().padStart(2, '0');
  };

  return (
    <div className='full-height full-width'>
      <Row className='full-height full-width'>
        <Col style={{ display: 'flex', flexDirection: 'column' }} className='full-height' span={state.is_max_576 ? 24 : 16}>
          <Row style={{ flexGrow: 1, marginBottom: '8px', overflow: 'auto' }}>
            <Col className='full-height' span={24}>
              <Card
                id='chatbody'
                className='full-height full-width'
                style={{ overflow: 'auto' }}
                extra={
                  state.is_max_576 ? (
                    <>
                      <Button onClick={showDrawer} size='small'>
                        {state.users ? state.users.length : 0} {STRINGS.players}
                      </Button>
                      <Drawer title='Basic Drawer' placement='right' closable={false} onClose={onClose} visible={visible}>
                        <Userlist users={state.users ? state.users : []}></Userlist>
                      </Drawer>
                    </>
                  ) : null
                }
              >
                {chatMessages &&
                  chatMessages.map((chatMessage) =>
                    chatMessage.user && state.user ? (
                      <div className='msg'>
                        <div className={chatMessage.user.id === state.user.id ? 'bubble alt' : 'bubble'}>
                          <div className='txt'>
                            <span className='name'>{chatMessage.user.username}</span>
                            <span className='timestamp'>{getReadableTime(chatMessage.time)}</span>
                            <span className='message'>{chatMessage.message}</span>
                          </div>
                          <div className={chatMessage.user.id === state.user.id ? 'bubble-arrow alt' : 'bubble-arrow'}></div>
                        </div>
                      </div>
                    ) : (
                      <div> {chatMessage.message}</div>
                    ),
                  )}
              </Card>
            </Col>
          </Row>
          <Row style={{ height: '40px' }}>
            <Col className='full-height' span={24}>
              <Form form={form} style={{ height: '100%', width: '100%' }}>
                <Form.Item id='test' name='test'>
                  <Search
                    autoFocus={true}
                    placeholder={STRINGS.enterMessage}
                    enterButton={STRINGS.send}
                    size='large'
                    style={{ height: '100% !important' }}
                    onSearch={(value, event) => {
                      if (state && state.socket) {
                        state.socket.emit(GameEvents.MESSAGE, value);
                        form.resetFields();
                      }
                    }}
                  />
                </Form.Item>
              </Form>
            </Col>
          </Row>
        </Col>
        {!state.is_max_576 ? (
          <Col span={8} style={{ paddingLeft: '8px' }}>
            <Card className='full-height'>
              <Userlist users={state.users ? state.users : []}></Userlist>
            </Card>
          </Col>
        ) : (
          <></>
        )}
      </Row>
    </div>
  );
};

export default Chat;
