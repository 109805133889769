import { createContext } from "react";
import { AppContextType } from "../types/Interfaces";
import { IS_MAX_992, IS_MAX_576 } from "../utils/constants";
import STRINGS from "../utils/languages";

const state: AppContextType = {
  language: STRINGS.getLanguage(),
  is_max_992: window.innerWidth < IS_MAX_992,
  is_max_576: window.innerWidth < IS_MAX_576,
  username: "",
  avatar: "🤔",
};

export const appContextDefaultValue = {
  state,
  setState: (state: AppContextType) => {},
};

export const AppContext = createContext(appContextDefaultValue);
