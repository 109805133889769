import socketIOClient from 'socket.io-client';
import { Card, Space, Button, Form, Input, Popover } from 'antd';
import { EditOutlined, PlayCircleOutlined, UserOutlined } from '@ant-design/icons';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import React, { useContext, useState } from 'react';
import '../css/global.css';
import STRINGS from '../utils/languages';
import Emojigenerator from '../components/Emojigenerator';
import { AppContext } from '../contexts/AppContext';
import { GameEvents } from '../utils/constants';

import { RoomId, User } from '../types/Interfaces';

const Main: React.FC = () => {
  const navigate = useNavigate();
  const { state, setState } = useContext(AppContext);
  const { roomId } = useParams<RoomId>();
  const content = <Emojigenerator></Emojigenerator>;

  const setValues = (user: User, socket: SocketIOClient.Socket) => {
    setState({
      ...state,
      socket,
      user: user!,
    });
  };
  const onFinish = (values: any) => {
    // const ENDPOINT = 'http://localhost:4999';
    console.log('test');
    console.log(process.env.REACT_APP_SOCKET_ENDPOINT!);
    // const socket = socketIOClient(process.env.REACT_APP_SOCKET_ENDPOINT!, { path: '/socket' });
    const socket = socketIOClient(process.env.REACT_APP_SOCKET_ENDPOINT!);

    if (roomId) {
      socket.emit(GameEvents.JOIN, { username: values.username, avatar: state.avatar, roomId }, (error: number, roomId: string, user: User) => {
        setValues(user, socket);
        navigate('/lobby/' + roomId);
      });
    } else
      socket.emit(GameEvents.CREATE, { username: values.username, avatar: state.avatar }, (error: number, roomId: string, user: User) => {
        if (roomId) {
          setValues(user, socket);
          navigate('/lobby/' + roomId);
        }
      });
  };

  return (
    <Card className='center-content--horizontal full-height'>
      <Card className='center-content--horizontal settings-width'>
        <div className='center-content--horizontal'>
          <div className='avatar'>
            {state.avatar}
            <Popover content={content} trigger='click'>
              <Button className='avatar-edit' type='default' shape='circle' icon={<EditOutlined />} />
            </Popover>
          </div>
          <Form onFinish={onFinish} size='large' name='control-ref' className='center-content--horizontal'>
            <Form.Item className='input-width' name='username' rules={[{ required: true }]}>
              <Input suffix={<UserOutlined />} placeholder={STRINGS.username} />
            </Form.Item>
            <Form.Item>
              {roomId ? (
                <Button icon={<PlayCircleOutlined />} type='primary' htmlType='submit'>
                  {STRINGS.joinGame}
                </Button>
              ) : (
                <Button icon={<PlayCircleOutlined />} type='primary' htmlType='submit'>
                  {STRINGS.createGame}
                </Button>
              )}
            </Form.Item>
          </Form>
        </div>
      </Card>
    </Card>
  );
};

export default Main;
