import { List } from 'antd';
import React, { useRef } from 'react';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import '../css/global.css';
import '../css/emojigenerator.css';

interface Props {
  users: {
    username: string; // I assume ID is defined elsewhere
    avatar: string;
  }[];
}

const Userlist: React.FC<Props> = ({ users }) => {
  return (
    <List
      dataSource={users}
      renderItem={(user) => (
        <List.Item style={{ display: 'flex', justifyContent: 'flex-start' }}>
          <div>{user.avatar}</div>
          <div style={{ marginLeft: '8px' }}>{user.username}</div>
        </List.Item>
      )}
    />
  );
};

export default Userlist;
