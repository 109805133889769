import '../css/global.css';
import '../css/game.css';

import React, { useContext, useEffect } from 'react';
import { Col, Row } from 'antd';
import { AppContext } from '../contexts/AppContext';
import STRINGS from '../utils/languages';
import Layout from 'antd/lib/layout/layout';
import Chat from '../components/Chat';
import EmojiCriber from '../components/Emojicriber';
import { GameEvents } from '../utils/constants';
import { User } from '../types/Interfaces';

const Game: React.FC = () => {
  const { state, setState } = useContext(AppContext);

  useEffect(() => {
    if (state.socket && state.user) {
      state.socket.emit(GameEvents.USERS, () => {});

      state.socket.on(GameEvents.USERS, (users: any) => {
        setState({
          ...state,
          users,
          user: users.find((user: User) => user.id === state?.user?.id),
        });
      });

      // state.socket.emit(GameEvents.)
    }
  }, [state.socket]);

  return (
    <Layout className='full-height full-width' style={{ background: 'white' }}>
      <Row gutter={[8, state.is_max_992 ? 0 : 8]} className='full-height'>
        <Col className='left-column--responsive' span={24} lg={12}>
          <EmojiCriber></EmojiCriber>
        </Col>
        <Col className='right-column--responsive' span={24} lg={12}>
          <Chat></Chat>
        </Col>
      </Row>
    </Layout>
  );
};

export default Game;
