import { Carousel } from 'antd';
import React, { useContext } from 'react';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import '../css/global.css';
import '../css/emojigenerator.css';
import { AppContext } from '../contexts/AppContext';

const Emojigenerator: React.FC = () => {
  const { state, setState } = useContext(AppContext);
  const availableEmojis = [
    '😀',
    '😃',
    '😄',
    '😁',
    '😆',
    '😅',
    '😂',
    '🤣',
    '😊',
    '😇',
    '🙂',
    '🙃',
    '😉',
    '😌',
    '😍',
    '😘',
    '😗',
    '😙',
    '😚',
    '😋',
    '😜',
    '😝',
    '😛',
    '🤑',
    '🤗',
    '🤓',
    '😎',
    '🤡',
    '🤠',
    '😏',
    '😒',
    '😞',
    '😔',
    '😟',
    '😕',
    '🙁',
    '😣',
    '😖',
    '😫',
    '😩',
    '😤',
    '😠',
    '😡',
    '😶',
    '😐',
    '😑',
    '😯',
    '😦',
    '😧',
    '😮',
    '😵',
    '😳',
    '😱',
    '😨',
    '😰',
    '😢',
    '😥',
    '🤤',
    '😭',
    '😓',
    '😪',
    '😴',
    '🙄',
    '🤔',
    '🤥',
    '😬',
    '🤐',
    '🤢',
    '🤮',
    '🤧',
    '😷',
    '🤒',
    '🤕',
    '🤨',
    '🤩',
    '🤯',
    '🧐',
    '🤫',
    '🤪',
    '🤭',
    '🥱',
    '🥳',
    '🥴',
    '🥶',
    '🥵',
    '😈',
    '👿',
    '🤬',
    '👹',
    '👺',
    '💩',
    '👻',
    '💀',
    '👽',
    '👾',
    '🤖',
    '🎃',
    '😺',
    '😸',
    '😹',
  ];

  const getPackagedEmojis = () => {
    const packagedEmojis = [];

    while (availableEmojis.length) {
      packagedEmojis.push(availableEmojis.splice(0, 20));
    }
    return packagedEmojis;
  };

  const updateAvatar = (event: any) => {
    const clickedEmoji = event.target;

    if (clickedEmoji) {
      setState({
        ...state,
        avatar: clickedEmoji.textContent,
      });
    }
  };

  return (
    <div>
      <Carousel
        arrows={true}
        prevArrow={<LeftOutlined className='carousel__icon' style={{ color: 'red' }} />}
        nextArrow={<RightOutlined className='carousel__icon' />}
      >
        {getPackagedEmojis().map((emojiPackage, pageIndex) => {
          return (
            <div className='carousel__page' key={'page_' + pageIndex}>
              {emojiPackage.map((emoji, emojiIndex) => {
                return (
                  <div onClick={(e) => updateAvatar(e)} className='carousel__emoji' key={'emoji' + pageIndex + '_' + emojiIndex}>
                    {emoji}
                  </div>
                );
              })}
            </div>
          );
        })}
      </Carousel>
    </div>
  );
};

export default Emojigenerator;
