export const IS_MAX_992 = 992;
export const IS_MAX_576 = 576;

export enum GameEvents {
  CONNECT = "connect",
  DISCONNECT = "disconnect",
  MESSAGE = "message",
  CREATE = "create",
  JOIN = "join",
  USERS = "users",
  GENRES = "genres",
  SETTINGS = "settings",
  START = "start",
  EMOJIS = "emojis"
}
